var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"saar-ops-dashboard@1.35.0"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import { CaptureConsole as CaptureConsoleIntegration } from "@sentry/integrations";
import * as Sentry from "@sentry/nextjs";
import { limitDepth } from "~/utils/objects/objectHelpers";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const ENVIRONMENT =
  (process.env.NODE_ENV === "development" && "local") ||
  process.env.SENTRY_ENVIRONMENT ||
  process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT;
Sentry.init({
  // Disable Sentry for local development
  enabled: ENVIRONMENT !== "local",

  // Limit the depth of the breadcrumbs to ensure that errors are not rejected by Sentry.
  beforeBreadcrumb(breadcrumb) {
    return limitDepth(breadcrumb, 1);
  },

  dsn:
    SENTRY_DSN ||
    "https://3e916807a5ff47e18d40b68e596200ed@sentry.emushrif.link/5",

  // A number between 0 and 1, controlling the percentage chance a given transaction will be sent to Sentry.
  tracesSampleRate: 0.65,

  /* By default Sentry SDKs normalize any context to a depth of 3,
   which in the case of sending Redux state you probably will want to increase that. */
  normalizeDepth: 7,

  // debug: ENVIRONMENT === "local",

  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  release: `${process.env.NEXT_PUBLIC_APP_NAME}@${process.env.NEXT_PUBLIC_APP_VERSION}`,

  // Tag the release with the environment
  environment: ENVIRONMENT,

  integrations: [
    new CaptureConsoleIntegration({
      levels: ["error"],
    }),
  ],
});
